
import React, {useState, useEffect} from 'react' 

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 


import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'


import {Link, Navigate, useNavigate}  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'
import { getLocalAccessToken } from '../Session/AuthSession'


const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})


function Dashboard() {

    const classes = useStyles() 
    const navigate = useNavigate() 


   useEffect(() => {
       const token = getLocalAccessToken() 
       if(!token){
           return navigate('/')
       }
   }, [])

    return (
        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10, xl: 20}}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

                <Stack 
                    width="400px"
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">

                        <Typography 
                            fontWeight="bold"
                            variant="h4"
                            component="div"
                            fontFamily="monospace">
                            
                                Dashboard
                        </Typography>

                      


                       

                
                      

                </Stack>

        </Box>
    )
}

export default Dashboard