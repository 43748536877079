import React, {forwardRef, useState, useEffect} from 'react' 
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'

import Stack from '@mui/material/Stack'


import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'

import Switch from '@mui/material/Switch' 


import Message from '../Components/Message' 
import { DialogTitle, FormControl, FormControlLabel, FormGroup, ImageList, ImageListItem, InputLabel, TextField } from '@mui/material'


import {addProduct} from '../Session/AuthSession' 
import { PhotoCamera } from '@mui/icons-material'
import styled from '@emotion/styled'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Input = styled('input')({
    display: 'none', 
})



function AddProduct(props) {
 
    const {open, onClose} = props 


    const [message, setMessage] = useState() 
    const [messageStatus, setMessageStatus] = useState(false) 
    const [messageIndicator, setMessageIndicator] = useState() 

    const [name, setName] = useState() 
    const [description, setDescription] = useState() 
    const [currency, setCurrency] = useState() 
    const [price, setPrice] = useState("0.0") 
    const [type, setType] = useState() 
    const [saleStatus, setSaleStatus] = useState(false) 
    const [marketStatus, setMarketStatus] = useState(false) 
    const [quantity, setQuantity] = useState() 



    const [photoURL, setPhotoURL] = useState() 
    const [photoGallery, setPhotoGallery] = useState([])  
    const [photoDialog, setPhotoDialog] = useState(false) 


    const [product, setProduct] = useState()

    const handlePhotoDialog = () => {
        setPhotoDialog(false) 
    }

    const messageClose = () => {
        setMessageStatus(false) 
    }

    const handlePhotoURL = (event) => {
        setPhotoURL(event.target.value)
    }

    const addPhotoURL = (event) => {
        event.preventDefault() 
        if(photoURL == null){
            setMessage('Field(s) cannot be blank') 
            setMessageIndicator('error') 
            setMessageStatus(true) 
            return 
        }

        let data = {
            url: photoURL
        } 

        setPhotoGallery(prevstate => [...prevstate, data]) 
    }



    const handleNameInput = (event) => {
        setName(event.target.value) 
    }

    const handleDescriptionInput = (event) => {
        setDescription(event.target.value) 
    }

    const handleCurrencyInput = (event) => {
        setCurrency(event.target.value) 
    }

    const handlePriceInput = (event) => {
        setPrice(event.target.value)
    }

    const handleTypeInput = (event) => {
        setType(event.target.value)
    }

    const handleSaleStatusInput = (event) => {
        setSaleStatus(event.target.checked) 
    }

    const handleMarketStatusInput = (event) => {
        setMarketStatus(event.target.checked) 
    }

    const handleQuantityInput = (event) => {
        setQuantity(event.target.value) 
    }


    const validateForm = (name, desc, price, type, quan) => {
        if(name == null || desc == null  || price == null || type == null || quan == null){
            return false
        }
        return true 
    }

    const configureProduct = () => {
        setProduct({

            name: name, 
            description: description, 
            product_price: price, 
            product_type: type, 
            on_sale: saleStatus, 
            on_market: marketStatus,
            quantity: quantity, 
            currency: currency,
            product_images: photoGallery

        })
    }

    const submitProduct = async (event) => {
        event.preventDefault() 
 

        if(!validateForm(name, description,price, type, quantity)){
            setMessage('Field(s) cannot be blank') 
            setMessageIndicator('error') 
            setMessageStatus(true) 
            return 

        }

        return await addProduct(product) 
                .then(() => {
                    setMessage("Product Uploaded")
                    setMessageIndicator("success")
                    setMessageStatus(true) 
                })

    
    }


  return (

    <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar 
        sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Product 
            </Typography>
            
          </Toolbar>
        </AppBar>

        <Stack 
            mt={10}
            mb={5}
            width="100%"

            alignItems="center"
            justifyContent="center" 

            direction="column"
            spacing={3}>

                <Typography 
                    variant="h5"
                    fontFamily="monospace"
                    component="div"
                    fontStyle="bold">
                        Add New Product
                </Typography>

                <form 

                    encType='multipart/form-data'
                    onSubmit={submitProduct}>

                    <Stack 
                    
                        width={{xs: '100%',sm: '100%',  md: '500px', lg: '600px', xl: '600px'}}

                        direction="column"
                        spacing={3}>

                        <TextField
                            variant="outlined"
                            label="Product Name"
                            value={name} 
                            type="text"
                            onChange={handleNameInput}/>

                        <TextField
                            variant="outlined"
                            label="Product Description"
                            value={description}
                            multiline 
                            rows={10} 
                            type="text"
                            onChange={handleDescriptionInput}/>

                        <TextField
                            variant="outlined"
                            label="Currency"
                            value={currency} 
                            type="text"
                            defaultValue="USD"
                            disabled
                            onChange={handleCurrencyInput}/>


                        <TextField
                            variant="outlined"
                            label="Product Price"
                            value={price} 
                            type="number"
                            inputProps={{
                                step: "1"
                            }}
                            onChange={handlePriceInput}/>


                        <TextField
                            variant="outlined"
                            label="Product Type"
                            value={type} 
                            onChange={handleTypeInput}/>

                        
                        

                        

                        <FormGroup>

                            <Stack 
                                maxWidth="100%"
                                direction="row"
                                spacing={3}>
                                    <FormControlLabel 
                                        control={
                                            <Switch 
                                                checked={saleStatus}
                                                onChange={handleSaleStatusInput}
                                                inputProps={{'aria-label': 'controlled'}}/>
                                        }
                                        label="Sales Status"
                                        
                                        />
                                    <FormControlLabel

                                        control={

                                        <Switch 
                                            checked={marketStatus} 
                                            onChange={handleMarketStatusInput}
                                            inputProps={{ 'aria-label': 'controlled'}}/>
                                        }

                                        label="Market Status" 
                                    />
                                    
                                    <IconButton 
                                        color="primary"
                                        aria-label="upload photo"
                                        component="span"
                                        onClick={() => (
                                            setPhotoDialog(true)
                                        )}>
                                            <PhotoCamera/>
                                    </IconButton>
                                    
                            </Stack>


                        </FormGroup>

                        <ImageList
                            cols={3}
                            rowHeight={164}>

                            {photoGallery.map((photo, key) => (
                                <ImageListItem key={key}>
                                    <img 
                                        width="20%"
                                        height="20%"
                                        src={photo.url}
                                        loading="lazy" 
                                    />

                                </ImageListItem>
                                
                            ))}

                        </ImageList>
                        

                        <TextField
                            variant="outlined"
                            label="Product Quantity"
                            value={quantity} 
                            type="number"
                            onChange={handleQuantityInput}/>


                        <Button 
                            variant="contained"
                            color="success"
                            onClick={configureProduct}
                            type="submit"
                            >
                                Add Product 
                        </Button>


                    </Stack>

                    
                </form>


        </Stack>

        <Message messageStatus={messageStatus} message={message} messageIndicator={messageIndicator} onClose={messageClose} />

            {/** Add Photo URL */}
            <Dialog onClose={handlePhotoDialog} open={photoDialog}>
                <DialogTitle>Add Image URL </DialogTitle>
                <Stack 
                    direction="column"
                    spacing={3}>

                        <form onSubmit={addPhotoURL}>

                            <Stack 
                                direction="column"
                                spacing={3}>
                                <TextField 
                                    variant="outlined"
                                    label="Add Image URL"
                                    value={photoURL}
                                    onChange={handlePhotoURL}
                                    type="text"/>
                                
                                <Button 
                                    variant="contained"
                                    color="success" 
                                    type="submit"
                                    >
                                    Add 
                                </Button>
                            </Stack>

                        </form>




                </Stack>
            </Dialog>
    </Dialog>
  )
}

export default AddProduct 




