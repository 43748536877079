import React, {useState, useEffect} from 'react'

import Box from '@mui/material/Box'

import {getLocalAccessToken} from './Session/AuthSession'
import { Routes, Route, Link, BrowserRouter } from "react-router-dom"


import StaffPortal from './Components/StaffPortal' 
import Access from './Pages/Access' 


function App() {

  const [isAuthenticated, setAuthentication] = useState(false) 



  useEffect(() => {
    const checkAuth = setInterval(() => {
      const token = getLocalAccessToken() 
      if(token){
        return setAuthentication(true)
      }
      return setAuthentication(false)

    }, 1)


    return () => clearInterval(checkAuth) 
  }, [])


  console.log('Authentication Status: ', isAuthenticated) 



  return (
    <BrowserRouter>
    
    {!isAuthenticated ? (

        <Box
          justifyContent="center"
          alignItems="center"
          maxWidth="100%">
            <Access/>
        </Box>

    ): (

      <Box
        display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
        justifyContent="center"
        alignItems="center"
        maxWidth="100%">
          <StaffPortal/>
      </Box>

    )}
    </BrowserRouter>
  )

}
export default App
