
import React, {forwardRef, useState, useEffect} from 'react' 

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 

import Card from '@mui/material/Card' 

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'


import RefreshIcon from '@mui/icons-material/Refresh';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Dialog from '@mui/material/Dialog'

import Switch from '@mui/material/Switch' 
import Chip from '@mui/material/Chip'

import Message from '../Components/Message' 

import ImageListItem from '@mui/material/ImageListItem'

import {Link, Navigate, useNavigate}  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'
import { getLocalAccessToken, getProducts } from '../Session/AuthSession'
import { getProductById, marketStatus, saleStatus, changeProductPrice, deleteProductById } from '../Session/AuthSession'

import AddProduct from '../Components/AddProduct'

import { Alert, CardContent, CardMedia, DialogContent, DialogTitle, Grid, ImageList } from '@mui/material'

const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


function Products() {

    const classes = useStyles() 
    const navigate = useNavigate() 


    const [products, updateProducts] = useState([]) 
    const [addProduct, setAddProduct] = useState(false) 


    const [message, setMessage] = useState() 
    const [messageStatus, setMessageStatus] = useState(false) 
    const [messageIndicator, setMessageIndicator] = useState()
    const messageClose = () => {
        setMessageStatus(false) 
    }

    const [productDetailName, setProductDetailName] = useState()   
    const [productDetailID, setProductDetailID] = useState() 
    const [productDescription, setProductDescription] = useState() 
    const [productPrice, setProductPrice] = useState() 
    const [productType, setProductType] = useState() 
    const [productImages, setProductImages] = useState([]) 
    const [productSale, setProductSale] = useState([]) 
    const [productMarket, setProductMarket] = useState([]) 
    const [productQuantity, setProductQuantity] = useState([]) 
    const [productCurrency, setProductCurrency] = useState([]) 


    //price dialog 
    const [editPriceDialog, setEditPriceDialog] = useState(false) 
    const [newPrice, setNewPrice] = useState()

    const handlePriceDialog = () => {
        setEditPriceDialog(false) 
    }

    const handleNewPriceInput = (event) => {
        setNewPrice(event.target.value) 
    }


    
    const [productDetail, setProductDetail] = useState(false) 

    const openProductDetail = () => {
        setProductDetail(true)
    }

    const closeProductDetail = () => {
        setProductDetail(false) 
    }
    
    const openProductDialog = () => {
        setAddProduct(true)
    }
    const closeProductDialog = () => {
        setAddProduct(false) 
    }

    const submitNewPrice = async (event) => {
        event.preventDefault() 
        if(newPrice == null){
            setMessage("Enter Price")
            setMessageIndicator("error") 
            setMessageStatus(true)
            return 
        }
        return await changeProductPrice(productDetailID, newPrice)
                .then(() => {
                    mountProductDetail(productDetailID) 
                    setMessage("Price updated")
                    setMessageIndicator("success")
                    setMessageStatus(true) 

                })
    }

    




    const mountProductDetail = async (id) => {
        const data = await getProductById(id)
        setProductDetailName(data.data.name)
        setProductDetailID(data.data.id) 
        setProductDescription(data.data.description) 
        setProductPrice(data.data.product_price) 
        setProductType(data.data.product_type) 
        setProductImages(data.data.product_images) 
        setProductSale(data.data.on_sale) 
        setProductMarket(data.data.on_market) 
        setProductQuantity(data.data.quantity) 
        setProductCurrency(data.data.currency)   
    }

    const mountProducts = async () => {
        const data = await getProducts() 
        updateProducts(data.data) 
    }

   useEffect(() => {
       const token = getLocalAccessToken() 
       if(!token){
           return navigate('/')
       }

       const mountProducts = async () => {
           const data = await getProducts() 
           updateProducts(data.data)
       }


       mountProducts() 
   }, [])

   console.log('Product Detail Name: ', productImages) 


    return (
        <Box 
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

                <Stack 
                    width={{xs: '100%', md: '100%', lg: '500px'}}
                    maxWidth="100%"
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center">

                        

                        <Stack  
                            direction="row" 
                            spacing={3}>

                                <Button 
                                    variant="contained"
                                    color="success"
                                    onClick={() => (
                                        openProductDialog(true)
                                    )}>
                                        Add New Product 
                                </Button>

                                <IconButton
                                    
                                    color="primary"
                                    size="large"
                                    aria-label="refresh">

                                        <RefreshIcon/>

                                    </IconButton>
                                    

                        </Stack>


                        <Grid
                            container
                            rowSpacing={{xs: 2, sm: 2, md: 5, lg: 5}}
                            columnSpacing={{xs: 2, sm: 2, md: 2, lg: 5, xl: 5}}
                            maxWidth="100%"
                            alignItems="center"
                            justifyContent="center">

                               {products.map((product, key) => (
                                   <Grid 
                                        item xs={12}
                                        key={key}
                                   >
                                        <Card 
                                            sx={{ boxShadow: 3}}
                                            >

                                                <CardMedia>
                                                    <ImageList>
                                                    {product.product_images.map((image, imagekey) => (
                                                        <img src={image.url}
                                                            key={imagekey}
                                                            width="100%"
                                                            height="100%"/>
                                                    ))}
                                                    </ImageList>
                                                </CardMedia>

                                                <CardContent>

                                                    <Stack 
                                                        direction="row"
                                                        spacing={3}
                                                        alignItems="center"
                                                        justifyContent="center">

                                                            <Button 
                                                                variant="contained"
                                                                onClick={() => (
                                                                    mountProductDetail(product.id), openProductDetail()
                                                                )}>
                                                                    View 
                                                            </Button>
                                                            
                                                            
                                                    </Stack>
                                                    
                                                </CardContent>
                                        </Card>
                                    </Grid>
                               ))}

                        </Grid> 


                </Stack>

                {/** Product Dialog */}
                <AddProduct open={addProduct} onClose={closeProductDialog}/>


                {/** Product Detail */}
                <Dialog
                    fullScreen
                    open={productDetail}
                    onClose={closeProductDetail}
                    TransitionComponent={Transition}
                    >
                        <AppBar 
                             sx={{ position: 'fixed' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={closeProductDetail} 
                                    aria-label="close"
                                    >
                                <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Product Detail 
                                </Typography>
                                
                            </Toolbar>
                        </AppBar>

                        <Stack 
                            mt={10}
                            mb={5}
                            maxWidth="100%"
                            alignItems="center"
                            justifyContent="center" 
                            direction="column"
                            spacing={3}>

                                <Typography 
                                    variant="h5"
                                    fontFamily="monospace"
                                    component="div"
                                    fontStyle="bold">
                                        Product Detail 
                                </Typography>




                                    <Stack 
                                        direction="column"
                                        spacing={3}
                                        alignItems="center"
                                        justifyContent="center" 
                                        maxWidth="100%"

                                        >
                                        
                                        <Card 

                                            sx={{ boxShadow: 3, width: '400px'}}
                                            >

                                                <CardMedia>
                                                    <ImageList>
                                                    {productImages.map((image, imagekey) => (
                                                        <img src={image.url}
                                                            key={imagekey}
                                                            width="100%"
                                                            height="100%" 
                                                            />
                                                    ))}
                                                    </ImageList>
                                                </CardMedia>

                                                <CardContent>

                                                    <Stack 
                                                        alignItems="center"
                                                        justifyContent="center" 
                                                        direction="column"
                                                        spacing={5}>

                                                        <Stack 
                                                             alignItems="center"
                                                             justifyContent="center" 
                                                             direction="row"
                                                             spacing={3}>

                                                                <Stack
                                                                    direction="column" 
                                                                    spacing={3}>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="Product Name"
                                                                            color="primary"/>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="Product Description"
                                                                            color="primary"/>
                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="Product Price"
                                                                            color="primary"/>
                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="Product Quantity"
                                                                            color="primary"/>
                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="Product Type"
                                                                            color="primary"/>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="on Market? "
                                                                            color="primary"/>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label="on Sale? "
                                                                            color="primary"/>

                                                                </Stack>


                                                                <Stack
                                                                    direction="column" 
                                                                    spacing={3}
                                                                    width="200px">

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label={productDetailName}
                                                                            color="secondary"/>

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label={productDescription}
                                                                            color="secondary"/>
                                                                        
                                                                        

                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label={productPrice}
                                                                            color="secondary"/>     

                                                                                                                                                    
                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label={productQuantity}
                                                                            color="secondary"/>
                                                                        <Chip 
                                                                            variant="outlined"
                                                                            label={productType}
                                                                            color="secondary"/>
                                                                        
                                                                        {productMarket ? (
                                                                            <Chip 
                                                                                variant="outlined"
                                                                                label="On Market"
                                                                                color="success"/>
                                                                        )
                                                                         : (
                                                                            <Chip 
                                                                                variant="outlined"
                                                                                label="Not on Market"
                                                                                color="error"/>
                                                                         )}

                                                                        {productSale ? (
                                                                            <Chip 
                                                                                variant="outlined"
                                                                                label="On Sale"
                                                                                color="success"/>
                                                                        )
                                                                         : (
                                                                            <Chip 
                                                                                variant="outlined"
                                                                                label="Not on Sale"
                                                                                color="error"/>
                                                                         )}

                                                                </Stack>


                                                        </Stack>


                                                        <Stack 
                                                            direction="column"
                                                            spacing={3}>

                                                            <Stack 
                                                                direction="row"
                                                                spacing={3}>
                                                                   

                                                                    

                                                                      
                                                            </Stack>


                                                            <Stack 
                                                                direction="row"
                                                                spacing={3}>
                                                                    {!productMarket ? (
                                                                        <Button
                                                                        variant="contained"
                                                                        color="success"
                                                                        onClick={() => (
                                                                            marketStatus(productDetailID, true).then(() => {
                                                                                mountProductDetail(productDetailID)
                                                                            })
                                                                        )}
                                                                        > 
                                                                            Push Market 
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                        variant="contained"
                                                                        color="error"
                                                                        onClick={() => (
                                                                            marketStatus(productDetailID, false).then(() => {
                                                                                mountProductDetail(productDetailID)
                                                                            })
                                                                        )}
                                                                        >
                                                                            Remove market  
                                                                        </Button>
                                                                    )}

                                                                    {!productSale ? (
                                                                        <Button
                                                                        variant="contained"
                                                                        color="success"
                                                                        onClick={() => (
                                                                            saleStatus(productDetailID, true).then(() => {
                                                                                mountProductDetail(productDetailID)
                                                                            })
                                                                        )}
                                                                        > 
                                                                            Push Sale
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                        variant="contained"
                                                                        color="error"
                                                                        onClick={() => (
                                                                            saleStatus(productDetailID, false).then(() => {
                                                                                mountProductDetail(productDetailID)
                                                                            })
                                                                        )}
                                                                        >
                                                                            Remove Sale   
                                                                        </Button>
                                                                    )}

                                                                      
                                                            </Stack>


                                                            <Button
                                                                variant="contained"
                                                                onClick={() => (
                                                                    setEditPriceDialog(true) 
                                                                )}
                                                                >
                                                                    Change Price 
                                                            </Button>

                                                            <Button 
                                                                variant="contained"
                                                                color="error"
                                                                onClick={() => {
                                                                    deleteProductById(productDetailID).then(() => {
                                                                        mountProducts()
                                                                        closeProductDetail()
                                                                    })
                                                                }}>

                                                                    Terminate Product 
                                                            </Button>


                                                        </Stack>
                                                    </Stack>

                                                        
                                                    
                                                </CardContent>
                                        </Card>

                                        

                                        
                                    </Stack>




                        </Stack>

                        <Dialog
                            open={editPriceDialog}
                            onClose={handlePriceDialog}
                            aria-labelledby="new-price"
                            aria-describedby="new-price-dialog"
                        >
                            <DialogTitle id="new-price-id">
                                {"Enter New Price"}
                            </DialogTitle>
                            <DialogContent>

                                <form onSubmit={submitNewPrice}>
                                    <Stack 
                                        direction="column"
                                        spacing={3}>

                                            <TextField 
                                                variant="outlined" 
                                                label="Enter New Price"
                                                type="number"
                                                value={newPrice}
                                                onChange={handleNewPriceInput}/>

                                            <Button 
                                                variant="contained"
                                                type="submit"
                                                color="success"
                                                >

                                                    Change Price 

                                            </Button>
                                    </Stack>
                                </form>
                            
                            </DialogContent>
                            
                        </Dialog>
                </Dialog>

                <Message messageStatus={messageStatus} message={message} messageIndicator={messageIndicator} onClose={messageClose} />


        </Box>
    )
}

export default Products