import axios from 'axios' 


const getLocalAccessToken = () => {
    const accessToken = localStorage.getItem('access')
    return accessToken 
}

const getLocalRefreshToken = () => {
    const refreshToken = localStorage.getItem('refresh') 
    return refreshToken 
}

const auth_instance = axios.create({
    baseURL: 'https://backend.godlyvizion.com/', 
    headers: {
        'Content-Type': 'application/json',
    }, 
})

auth_instance.interceptors.request.use( (config) => {
    const token = getLocalAccessToken() 
    if(token){
        config.headers['Authorization'] = "Bearer " + token 
    }
    return config 
}, (error) => {
    return Promise.reject(error)
})


auth_instance.interceptors.response.use( (response) => {
    return response 
}, async (error) => {
    const originalConfig = error.config 
    if(error.response){
        if(error.response.status == 401 && !originalConfig._retry){
            originalConfig._retry = true 
            try{
                const rs = await refreshToken()
                const { access } = rs.data 
                localStorage.setItem('access', access)
                auth_instance.defaults.headers.common['Authorization'] = "Bearer " + access 
                return auth_instance(originalConfig) 
            }catch(_error){
                if(_error.response && _error.response.data){
                    return Promise.reject(_error.response.data)
                }
                return Promise.reject(_error)
            }
        }

        if(error.response.status == 403 && error.response.data){
            return Promise.reject(error.response.data) 
        }
    }

    return Promise.reject(error)


})

const refreshToken = async () => {
    return await auth_instance.post('api/refresh/', {
        refresh: getLocalRefreshToken(), 
    })
}


const signin = async (credentials) => {
    return await auth_instance.post('api/token/', credentials)
}

const getProducts = async () => {
    return await auth_instance.get('products/products/')
}

const addProduct = async (product) => {
    return await auth_instance.post('products/products/', product) 
}

const getProductById = async (id) => {
    return await auth_instance.get(`products/products/${id}/`)
}

const marketStatus = async (id, status) => {
    let data = { on_market: status}
    return await auth_instance.patch(`products/products/${id}/`, data)
}

const saleStatus = async (id, status) => {
    let data = { on_sale: status}
    return await auth_instance.patch(`products/products/${id}/`, data) 
}

const changeProductPrice = async (id, new_price) => {
    let data = {product_price: new_price}
    return await auth_instance.patch(`products/products/${id}/`, data) 
}

const getTransactions = async () => {
    return await auth_instance.get('store/transactions/')
}

const getTransactionById = async (id) => {
    return await auth_instance.get(`store/transactions/${id}/`)
}


const setTransactionTracking = async (id, data) => {
    let new_tracking = {tracking_number: data}
    return await auth_instance.patch(`store/transactions/${id}/`, new_tracking)
}


const deleteProductById = async (id) => {
    return await auth_instance.delete(`products/products/${id}/`)
}

export {
    getLocalAccessToken, 
    signin, 
    getProducts, 
    addProduct, 
    getProductById, 
    marketStatus, 
    saleStatus, 
    changeProductPrice, 
    deleteProductById, 
    getTransactions, 
    getTransactionById,
    setTransactionTracking,
}



