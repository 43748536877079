

import React, {forwardRef, useState, useEffect} from 'react' 

import Box from '@mui/material/Box' 
import Stack from '@mui/material/Stack' 

import Card from '@mui/material/Card' 
import CardContent from '@mui/material/CardContent' 

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'


import RefreshIcon from '@mui/icons-material/Refresh';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import Dialog from '@mui/material/Dialog'

import Switch from '@mui/material/Switch' 
import Chip from '@mui/material/Chip'

import Message from '../Components/Message' 

import {Link, Navigate, useNavigate}  from 'react-router-dom' 

import { makeStyles } from '@mui/styles'
import { getLocalAccessToken, getTransactions, getTransactionById, setTransactionTracking } from '../Session/AuthSession'
import { CardMedia, DialogContent, ImageList } from '@mui/material'


const useStyles = makeStyles({
    container: {

        boxShadow: '4px 10px 16px 1px rgba(4,4,4,.3)',
        backgroundColor: 'white',
    
   }
})


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})



function Transactions () {

    const classes = useStyles() 
    const navigate = useNavigate() 

    const [transactionBucket, updateTransactionBucket] = useState([]) 
    const [transactionID, setTransactionID] = useState() 


    // transaction detail 

    const [userTransaction, setUserTransaction] = useState([]) 
    const [detailUsername, setDetailUsername] = useState() 
    const [detailShipping, setDetailShipping] = useState() 
    const [detailPaid, setDetailPaid] = useState() 
    const [detailPaymentStatus, setDetailPaymentStatus] = useState()

    const [trackingNumber, setTrackingNumber] = useState(null)
    const handleTracking = (event) => {
        setTrackingNumber(event.target.value) 
    }
    const sendTracking = async (id) => {
        return await setTransactionTracking(id, trackingNumber).then((() => {
            
        }))
    }




    const [transactionDetailStatus, setTransactionDetailStatus] = useState(false)
    
    const closeTransactionDetail = () => {
        setTransactionDetailStatus(false) 
    }

    const mountDetailTransaction = async (id) => {
        const transaction = await getTransactionById(id)
        setUserTransaction(transaction.data.user_transaction)
        setDetailUsername(transaction.data.user)
        setDetailShipping(transaction.data.shipping)
        setDetailPaymentStatus(transaction.data.payment_status)
        setDetailPaid(transaction.data.price) 
        setDetailShipping(transaction.data.shipping)
        
    }




   useEffect(() => {
       const token = getLocalAccessToken() 
       if(!token){
           return navigate('/')
       }

       const mountTransactionBucket = async () => {
           const transactions = await getTransactions()
           updateTransactionBucket(transactions.data) 
       }


       mountTransactionBucket() 

   }, [])

   console.log('Transaction Bucket: ', transactionBucket)
   console.log('Transaction Detail: ', userTransaction) 

    return (
        <Box 
            mt={{xs: 10, sm: 10, md: 10, lg: 10,}}
           // ml={10}
            display={{ xs: "flex", sm: "flex", md: "flex", lg: "flex"}}
            justifyContent="center"
            alignItems="center"
            maxWidth="100%">

                <Stack 
                    direction="column"
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="100%">

                        <Typography 
                            fontWeight="bold"
                            variant="h4"
                            component="div"
                            fontFamily="monospace">
                            
                                Transactions 
                        </Typography>


                        <Stack 
                            direction="column"
                            spacing={3}
                            justifyContent="center"
                            alignItems="center">


                                {transactionBucket.map((transaction, id) => (
                                    <Card
                                        
                                        key={id}>

                                            <CardContent>

                                            <Stack 
                                                direction="column"
                                                spacing={3}
                                                justifyContent="center"
                                                alignItems="center"
                                                >




                                            <Stack 
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center">

                                                <Chip
                                                    variant="h5"
                                                    component="div"
                                                    fontFamily="monospace"
                                                    fontStyle="bold"
                                                    label={transaction.user}>
                                                </Chip>

                                                <Chip
                                                    variant="h5"
                                                    component="div"
                                                    fontFamily="monospace"
                                                    fontStyle="bold"
                                                    label={transaction.email}>
                                                </Chip>

                                            </Stack>


                                            <Button 
                                                variant="text"
                                                onClick={() => (
                                                    setTransactionDetailStatus(true), setTransactionID(transaction.id),  mountDetailTransaction(transaction.id)
                                                )}
                                                >
                                                    Open 
                                                </Button>

                                            </Stack>

                                            </CardContent>

                                    </Card>
                                ))}

                                

                        </Stack>

                      


                       

                
                      

                </Stack>


                {/** Transaction Detail */}
                <Dialog
                    fullScreen
                    open={transactionDetailStatus}
                    onClose={closeTransactionDetail}
                    TransitionComponent={Transition}
                    >
                        <AppBar 
                             sx={{ position: 'fixed' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={closeTransactionDetail} 
                                    aria-label="close"
                                    >
                                <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Transaction Detail 
                                </Typography>
                                
                            </Toolbar>
                        </AppBar>

                        <DialogContent>
                            <Stack 
                                mt={10}
                                direction="column"
                                spacing={3}
                                justifyContent="center"
                                alignItems="center">

                                            <Stack 
                                                direction="row"
                                                spacing={3}
                                                justifyContent="center"
                                                alignItems="center">

                                                <Stack 
                                                    direction="column"
                                                    spacing={3}
                                                    justifyContent="center"
                                                    alignItems="center">

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="primary"
                                                            label="Transaction ID">

                                                        </Chip>

                                                        

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="primary"
                                                            label="Username">

                                                        </Chip>

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="primary"
                                                            label="Payment Amount">

                                                        </Chip>     


                                                        <Chip
                                                            variant="h5"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="primary"
                                                            label="Shipping Info ">
                                                        </Chip>        
                                                    
                                                </Stack>


                                                <Stack 
                                                    direction="column"
                                                    spacing={3}
                                                    justifyContent="center"
                                                    alignItems="center">

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="success"
                                                            label={transactionID}>

                                                        </Chip>

                                                        

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="success"
                                                            label={detailUsername}>

                                                        </Chip>

                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="success"
                                                            label={`$ ${detailPaid}`}>

                                                        </Chip>    


                                                        <Chip
                                                            variant="h5"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="success"
                                                            label={detailShipping}>
                                                        </Chip>         
                                                    
                                                </Stack>

                                            </Stack>

                                {userTransaction.map((product, key) => (

                                    <Card key={key}>
                                        <Stack 
                                            direction="column"
                                            spacing={3}
                                            justifyContent="center"
                                            alignItems="center">




                                                

                                            <Stack 
                                                direction="row"
                                                spacing="1"
                                                justifyContent="center"
                                                alignItems="center">

                                                    <CardMedia>
                                                        <ImageList>
                                                        {product.product_images.map((image, imagekey) => (

                                                            <img src={image.url}
                                                                key={imagekey}
                                                                width="200px"
                                                                height="100%" 
                                                                />
                                                        ))}

                                                        </ImageList>
                                                    </CardMedia>

                                            </Stack>


                                            <Stack 
                                                direction="column"
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center"
                                               >

                                                    <Chip 
                                                        variant="text"
                                                        component="div"
                                                        fontFamily="monospace"
                                                        fontStyle="bold"
                                                        label={`$ ${product.product_price} ${product.currency}`}>

                                                     </Chip>


                                                     {product.on_sale ? (
                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="success"
                                                            label="Bought on Sale">

                                                        </Chip>
                                                     ): (
                                                        <Chip 
                                                            variant="text"
                                                            component="div"
                                                            fontFamily="monospace"
                                                            fontStyle="bold"
                                                            color="info"
                                                            label="Didn't Buy on Sale ">

                                                        </Chip>
                                                     )}


                                                        

                                            </Stack>


                                        </Stack>

                                    </Card>
                                ))}

                                

                            </Stack>
                        </DialogContent>

                </Dialog>

        </Box>
    )
}

export default Transactions 