import React, {useState, useEffect} from 'react' 


import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack' 


import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button' 

import Typograhpy from '@mui/material/Typography'

import Snackbar from '@mui/material/Snackbar' 
import Alert from  '@mui/material/Alert' 

import {signin} from '../Session/AuthSession' 

function Access() {


    const [usernameField, setUsernameField] = useState()
    const [passwordField, setPasswordField] = useState() 


    const [alertMessage, setAlertMessage] = useState() 
    const [alertStatus, setAlertStatus] = useState(false) 
    const [alertCode, setAlertCode] = useState() 


    const handleAlertStatus = () => {
        setAlertStatus(false) 
    }

    const handleUsernameInput = (event) => {
        setUsernameField(event.target.value)
    }
    const handlePasswordInput = (event) => {
        setPasswordField(event.target.value)
    }

    const validateAccess = (username, password) => {
        if(username == null || password == null){
            return false
        }
        return true 
    }

    const getAccess = async (event) => {
        event.preventDefault() 
        let data = {
            username: usernameField, 
            password: passwordField,
        }

        if(!validateAccess(usernameField, passwordField)){
            setAlertMessage('Cannot leave Field(s) blank!')
            setAlertCode("error")
            setAlertStatus(true) 
            return 
        }


        const token = await signin(data)
                      .catch((error) => {
                          setAlertMessage(error.message)
                          setAlertCode("error")
                          setAlertStatus(true) 
                      })

        const {access, refresh} = token.data 
        localStorage.setItem('access', access)
        localStorage.setItem('refresh', refresh) 
    }

    
    return (
        <Box
          sx={{mt: 20}}
          justifyContent="center"
          alignItems="center"
          maxWidth="100%">


            <Snackbar 
                open={alertStatus}
                autoHideDuration={6000}
                onClose={handleAlertStatus}>
                    <Alert 
                        variant="filled"
                        severity={alertCode}>
                            {alertMessage}
                    </Alert>

            </Snackbar>

            
            <Stack 
                justifyContent="center"
                alignItems="center" 
                direction="column"
                spacing={3}>

                    <Typograhpy
                        variant="h4"
                        fontFamily="monospace">
                            Staff Portal 
                    </Typograhpy>

                    <form onSubmit={getAccess}>

                        <Stack  
                            direction="column"
                            spacing={3}
                            alignItems="center"
                            justifyContent="center">

                            <TextField 
                                variant="outlined"
                                label="Username"
                                type="text"
                                onChange={handleUsernameInput}
                                />

                            <TextField 
                                variant="outlined"
                                label="Password"
                                type="password"
                                onChange={handlePasswordInput}
                                />

                            <Button 
                                variant="contained"
                                type="submit"
                                >
                                    Get Access 
                            </Button>
                        </Stack>

                    </form>

            </Stack>
        </Box>
    )
}

export default Access 